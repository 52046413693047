<template>
        <v-layout row wrap class="">
             <v-flex xs12 class="pa-3">
                 <v-layout row wrap class="green mt-5 mb-5 pa-3">
                <v-flex xs8 class="pt-3 pb-3">
                    <span class="caption white--text"><b>INSIGHT</b></span>
                    <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>FRANCHISE</b></span>
                    <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text" style="text-transform:uppercase"><b>{{$route.params.state}}</b></span>
                </v-flex>
                <v-flex xs4 class="text-right pt-2">
                <v-btn @click="addFranchise" class="white black--text pl-10 pr-10" rounded><b>ADD FRANCHISE</b></v-btn>
                
                </v-flex>
            </v-layout>
        </v-flex>
          <v-flex xs12>
          </v-flex>
          <v-flex xs12>
                <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th></th>
          <th class="text-left">Name</th>
          <th class="text-left">File Number</th>
          <th class="text-left">Phone</th>
          <th class="text-left">District</th>
          <th class="text-left">Pcht / Muni / corp</th>
          <th>Code</th>
          <!-- <th>Password</th> -->
          <th>Actions</th>
          <!-- <th class="text-left">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in filteredFranchises" :key="item._id">
          <td>{{index+1}}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.file_no }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.district }}</td>
          <td style="width:200px" class="grey--text">{{ getLocalities(item.locality) }}</td>
          <td class="green--text">{{item.code}}</td>
          <!-- <td class="pink--text"> {{ getPassword(item) }}</td> -->
          
          

          <td style="width:200px">
            <v-icon class="green--text" @click="viewFranchiseDetails(item._id)" >search</v-icon>
            <v-icon class="green--text text--darken-4 ml-5" @click="editFranchiseDetails(item._id)" >edit</v-icon>
            <!-- <v-icon class="green--text text--darken-4 ml-5" @click="licenses(item._id)" >list</v-icon> -->
            <v-icon v-if="ADMIN" class="pink--text ml-5" @click="deleteFranchise(item._id)">delete</v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-flex>
        </v-layout>
    
</template>

<script>
import {mapGetters} from 'vuex'
import Axios from 'axios'
import { BASE_URL } from '../config'
export default {
    computed : {
        ...mapGetters(['FRANCHISES','ADMIN']),
        filteredFranchises(){
          let _filteredFranchises = []
          
          for(let  i=0;i<this.FRANCHISES.length;i++){
                if(this.FRANCHISES[i].locality_type == "TALUK"){_filteredFranchises.push(this.FRANCHISES[i])}                   
            }
            _filteredFranchises.sort()
            return _filteredFranchises
        }
                
    },
    mounted(){
        let payload = {state : this.$route.params.state}
        this.$store.dispatch("GET_FRANCHISES",payload)
    },
    methods : {
      async sendMail(email,name,locality,phone,frCode,id){
        let emailStr = email.split("@")[0]
        let passwordStr = emailStr+ phone.substring(5,10)
        let url = "http://3.7.194.167:5006/api/v1/sendEmail"
        let payload = {
          email : email,
          name : name,
          locality : locality,
          phone : phone,
          password : passwordStr,
          frCode : frCode,
          id : id
        }
        let result = await Axios.post(url,payload)
        
      },

      // async savePassword(){
      //   var url = BASE_URL + "/add-passwords"
      //   console.log(url)
      //   for(let i=0;i<this.FRANCHISES.length;i++){
      //     let item = this.FRANCHISES[i]
      //     let emailStr = item.email.split("@")[0]
      //     let passwordStr = emailStr+ item.phone.substring(5,10)
      //     let payload = {
      //         password : passwordStr,
      //         id : item._id
      //       }
      //     let {data} = await Axios.post(url,payload)
      //     console.log(data)
          
      //   }
      // },

      getPassword(item){
        
        let emailStr = item.email.split("@")[0]
        let passwordStr = emailStr+ item.phone.substring(5,10)
        return passwordStr
      },
      getLocalities(loc){
          let _locs= "";
          for(var i=0;i<loc.length;i++){
              _locs += loc[i].toUpperCase()
              if(loc.length-1!=i){
                  _locs += ", "
              }
          }
          return _locs

      },
      addFranchise(){
        this.$router.push({name : 'add-franchise'})
      },
      viewFranchiseDetails(id){
        this.$router.push({name : 'franchise-details',params : {id : id}})
      },
      editFranchiseDetails(id){
        this.$router.push({name : "edit-franchise-other-states",params : {id :id}})
      },
      licenses(id){
        this.$router.push({name : "license",params : {id :id}})
      },
     
      deleteFranchise(id){
        swal({
                  title: "Are you sure?",
                  text: "The Taluk Franchise will be removed permanantly!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    let payload = {id : id}
                    this.$store.dispatch("DELETE_FRANCHISE",payload)
                    swal("Deleted", "Taluk Franchise removed successfully!", {
                      icon: "success",
                    });
                    this.$router.go('')
                  } else {
                    swal("The Taluk Franchise was not deleted!");
                  }
                });
      }
    }

}
</script>