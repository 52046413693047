<template>
    <v-layout row wrap class="mb-10">
           <v-flex xs12 class="pa-3">
               <v-layout row wrap class="green mt-5 mb-5 pa-3">
                <v-flex xs8 class="pt-3 pb-3">
                    <span class="caption white--text"><b>INSIGHT</b></span>
                    <span class="caption ml-3 mr-3 white--text"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>ADD FRANCHISE</b></span>
                    <span class="caption ml-3 mr-3 white--text"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>TALUK</b></span>
                    <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text" style="text-transform:uppercase"><b>{{$route.params.state}}</b></span>
                </v-flex>
                <v-flex xs4 class="text-right">
            </v-flex>
            </v-layout>
               <v-layout row wrap class="white pt-10 pb-10">
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>NAME</b></p>
                <v-text-field label="Full Name" v-model="name" outlined class="rounded-0" single-line="" ></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>PHONE</b></p>
                <v-text-field label="Phone" v-model="phone" outlined single-line></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>EMAIL</b></p>
                <v-text-field label="Email" v-model="email" outlined single-line></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>IN CHARGE</b></p>
                <v-text-field label="In charge" v-model="in_charge" outlined single-line></v-text-field>
            </v-flex>
            <v-flex xs12 class="pl-5 pr-5">
                <p class="caption"><b>ADDRESS</b></p>
                <v-textarea label="Address" v-model="address" outlined single-line></v-textarea>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>STATE</b></p>
                <!-- <v-text-field v-model="state"></v-text-field> -->
                <v-select label="state" :items="states" v-model="state" outlined single-line disabled></v-select>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>DISTRICT</b></p>
                <v-select
                    :items="districts"
                    label="District"
                    v-model="district"
                    single-line outlined
                    @change="getLocalities"
                ></v-select>
                
            </v-flex>
            <v-flex xs12 class="pa-5">
                <v-divider></v-divider>
            </v-flex>
            <!-- <v-flex xs12 class="pl-5 pr-5">
                <p class="caption"><b>TALUK</b></p>
                <v-select
                    :items="locality_types"
                    label="Panchayat / Munic. / Corp."
                    v-model="locality_type"
                    single-line outlined
                    @change="filterLocalities"
                ></v-select>
            </v-flex> -->
            <v-flex xs12 class="pl-5 pr-5">
                <p class="caption"><b>TALUK</b></p>
                <!--  -->
                <v-select label="Locality" v-model="locality" outlined single-line multiple chips :items="filteredLocalities" item-text="locality"></v-select>
            </v-flex>
            
            <v-flex xs12 class="pl-5 pr-5 text-right">
                <v-btn large class="green pl-10 pr-10 white--text rounded-0"  @click="addFranchise">ADD FRANCHISE</v-btn>
            </v-flex>
               </v-layout>
           </v-flex>
        </v-layout>
    
</template>

<script>
import Axios from 'axios'
import {BASE_URL, LOCALITY_URL} from '../config'
export default {
    
    data(){
        return{
            name : "",
            phone : "",
            email : "",
            in_charge : "",
            address : "",
            location : "",
            district : "",
            panchayaths : [],
            municipalities : [],
            corporations : [],
            locality : '',
            locality_type : '',
            
           states : [
            "Andaman & nicobars",
            "Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chattisgarh",
            "Dadra And Nagar Haveli",
            "Haryana",
            "Himachal pradesh",
            "Goa",
            "Gujarath",
            "Jammu & Kashmir",
            "jharkhand",
            "Karnataka",
            "Kerala",
            "Ladakh",
            "Madhya Pradesh",
            "Maharashtra",
            "Nagaland",
            "Odisha",
            "Puducherry",
            "Punjab",
            "Rajasthan",
            "Tamilnadu",
            "Telangana",
            "Tripura",
            "UP",
            "utharakhand",
            "Delhi"
            ],
            locality_types: ["Panchayath","Municipality","Corporation"],
            districts : [],
            state : "",
            locality : [ ],
            filteredLocalities : [],
        }   
    },
    computed : {
      
    },
    async mounted(){
        this.state = this.$route.params.state
        
        {
            // let url = BASE_URL + "/district-list?state="+this.state
            let url = BASE_URL +  "/district-list-in-state?state=" + encodeURIComponent(this.state)
            let {data} = await Axios.get(url)
            this.districts = data
        }
        if(this.state == "Kerala"){
            this.districts = ["Alappuzha","Ernakulam","Idukki","Kannur","Kasaragod","Kollam","Kottayam","Kozhikode","Malappuram","Palakkad","Pathanamthitta","Thiruvananthapuram","Thrissur","Wayanad"]
        }
        // if(this.state == "Maharashtra"){
        //     this.districts = this.districtsMaharashtra
        // }else if(this.state == "Karnataka"){
        //     this.districts = this.districtsKarnataka
        // }
        // else if(this.state == "Andhra Pradesh"){
        //     this.districts = this.districtsAndhra
        // }
        // else if(this.state == "Telangana"){
        //     this.districts = this.districtsTelangana
        // }
        // else if(this.state == "Tamil Nadu"){
        //     this.districts = this.districtsTamilNadu
        // }else if(this.state == "Andaman and Nicobar Islands"){
        //     this.districts = this.districtsAndaman
        // }
    },
    methods : {
        addFranchise(){
            let payload = {
                name : this.name,
                phone : this.phone,
                email : this.email,
                in_charge : this.in_charge,
                address : this.address,
                locality_type : "TALUK",
                locality : this.locality,
                district : this.district,
                state : this.state,
            }
            this.$store.dispatch("ADD_FRANCHISE",payload)
            this.$toastr.s("SAVED", "Account added successfully");
            this.name = ""
            this.phone = ""
            this.email = ""
            this.in_charge = ""
            this.address = ""
            this.location = ""
            this.district = ""
            this.state = ""
            this.locality_type = ""
            this.locality = ""
            this.localities = []

        },
        async getLocalities(){
            let url = BASE_URL + "/localities?district="+this.district+"&state="+this.state
            let {data} = await Axios.get(url)
            this.localities = data
            this.filteredLocalities = []
            for(let  i=0;i<this.localities.length;i++){
                if(this.localities[i].type.toUpperCase() == "TALUK"){this.filteredLocalities.push(this.localities[i])}                   
            }
            this.filteredLocalities.sort()
            
            
        },
        
    }
}
</script>

