<template>
  <v-app>
    <app-tool-bar v-if="LOGIN"></app-tool-bar>
   <v-main v-if="LOGIN" class="custom-style" style="height:100%">
      <div class="blue-grey lighten-5 pl-10 pr-10">
        <router-view></router-view>
      </div>
      
    </v-main>
    <v-main v-else>
      <login></login>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import ToolBar from './components/ToolBar'
import Login  from './views/Login'
export default {
  name: 'App',

  components: {
    AppToolBar : ToolBar,
    Login
  },
  computed : {
    ...mapGetters(["LOGIN"])
  },
  data: () => ({
    //
  }),
};
</script>

<style>
.custom-style{
    font-family: 'Montserrat', sans-serif;
    background-color: #eceff1;
  }
</style>

