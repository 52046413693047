<template>
    <v-layout row wrap>
        <v-flex xs12 class="pa-3">
               <v-layout row wrap class="green mt-5 mb-5 pa-3">
                <v-flex xs8 class="pt-3 pb-3">
                    <span class="caption white--text"><b>INSIGHT</b></span>
                    <span class="caption ml-3 mr-3 white--text"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>STATES</b></span>
                </v-flex>
                <v-flex xs4 class="text-right"></v-flex>
               </v-layout>
        </v-flex>
        <v-flex xs12>
            <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          
          <th class="text-left">#</th>
          <th class="text-left">State</th>
          
          <th>Actions</th>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in states" :key="item">
          <td>{{index+1}}</td>
          <td style="text-transform:uppercase">{{ item }}</td>

          <td style="width:200px">
            <v-icon class="green--text" @click="viewFranchises(item)" >search</v-icon>
            <v-icon class="green--text text--darken-4 ml-5" @click="addFranchise(item)" >add</v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    data(){
        return{
            // states : ["Andaman and Nicobar Islands","Andhra Pradesh", "Arunachal Pradesh","Assam","Bihar","Chandigarh","Chhattisgarh","Dadra And Nagar Haveli",
            // "Daman And Diu", "Delhi","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu And Kashmir","Jharkhand", "Karnataka","Lakshadweep","Madhya Pradesh",
            // "Maharashtra","Manipur","Meghalaya","Mizoram","Nagaland","Odisha","Puducherry","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura","Uttar Pradesh","Uttarakhand","West Bengal"]
            states : [
            "Andaman & nicobars",
            "Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chattisgarh",
            "Dadra And Nagar Haveli",
            "Haryana",
            "Himachal pradesh",
            "Goa",
            "Gujarath",
            "Jammu & Kashmir",
            "jharkhand",
            "Karnataka",
            "Ladakh",
            "Madhya Pradesh",
            "Maharashtra",
            "Nagaland",
            "Odisha",
            "Puducherry",
            "Punjab",
            "Rajasthan",
            "Tamilnadu",
            "Telangana",
            "Tripura",
            "UP",
            "utharakhand",
            "Delhi"
            ],
        }
    },
    methods : {
        addFranchise(state){
          
            this.$router.push({name : "add-franchise-other-states",params : {state : state}})
        },
        viewFranchises(state){
            this.$router.push({name : "FranchisesOtherStates",params : {state : state}})
        }
    }
}
</script>