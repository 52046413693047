<template>
    
        <v-layout row wrap>
            <v-flex xs12 class="pa-3">
                 <v-layout row wrap class="green mt-5 mb-5 pa-3">
                <v-flex xs8 class="pt-3 pb-3">
                    <span class="caption white--text"><b>INSIGHT</b></span>
                    <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>POST OFFICE FRANCHISE</b></span>
                     <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>DETAILS</b></span>
                </v-flex>
                <v-flex xs4 class="text-right pt-2">
                
                <!-- <v-btn @click="editFranchise" class="green white--text rounded-0">EDIT</v-btn> -->
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 md12 >
        <div class="text-center" v-if="loading">
    <v-progress-circular
      :size="50"
      color="green"
      indeterminate
    ></v-progress-circular>
        </div>
    </v-flex>
            <v-flex xs12 md8>
               
                <v-simple-table>
                <template v-slot:default>
                <tbody>
                    <tr>
                        <td  class="caption"><b>NAME</b></td>
                        <td>{{FRANCHISE_DETAILS.name}}</td>
                    </tr>
                    <tr>
                        <td  class="caption"><b>PHONE</b></td>
                        <td>{{FRANCHISE_DETAILS.phone}}</td>
                    </tr>
                    <tr>
                        <td  class="caption"><b>EMAIL</b></td>
                        <td>{{FRANCHISE_DETAILS.email}}</td>
                    </tr>
                    <tr>
                        <td  class="caption"><b>IN CHARGE</b></td>
                        <td>{{FRANCHISE_DETAILS.in_charge}}</td>
                    </tr>
                    <tr>
                        <td  class="caption"><b>ADDRESS</b></td>
                        <td>{{FRANCHISE_DETAILS.address}}</td>
                    </tr>
                    <tr>
                        <td  class="caption"><b>STATE</b></td>
                        <td>{{FRANCHISE_DETAILS.state}}</td>
                    </tr>
                    <tr>
                        <td  class="caption"><b>DISTRICT</b></td>
                        <td>{{FRANCHISE_DETAILS.district}}</td>
                    </tr>
                    
                    <!-- <tr>
                        <td  class="caption"><b>PINCODE</b></td>
                        <td>{{FRANCHISE_DETAILS.pincode}}</td>
                    </tr>
                    
                    <tr>
                        <td  class="caption"><b>POST OFFICE</b></td>
                        <td>{{FRANCHISE_DETAILS.po}}</td>
                    </tr> -->
                     <tr>
                        <td  class="caption"><b>PASSWORD</b></td>
                        <td><v-btn @click="viewPassword" v-if="password==''" small class="green white--text pl-10 pr-10" rounded>View Password</v-btn>
                        <p v-else class="pt-3">{{password}}</p>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <br><br>
            <p class="white caption pa-5"><b>POST OFFICES</b></p>
            <v-simple-table>
                <tbody>
                    <tr>
                        <td>
                            {{FRANCHISE_DETAILS.pincode}}
                        </td>
                        <td>
                            {{FRANCHISE_DETAILS.po}}
                        </td>
                    </tr>
                    <tr v-for="items in FRANCHISE_DETAILS.pos" :key="items.pin">
                        <td>
                            {{items.pin}}
                        </td>
                        <td>
                            {{items.postoffice}}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <tr>
                    <td  class="caption"><b>ACADEMIC COURSE PURCHASED IN <p class="caption"><span v-for="items in FRANCHISE_DETAILS.pos" :key="items.pin">{{items.postoffice}}, </span></p></b>{{FRANCHISE_DETAILS.po}}</td>
                     <div >
                        <v-simple-table class="mx-5">
                            <thead>
                                <tr>
                                    <th class="green--text">SL NO </th>
                                    <th class="green--text">USER NAME</th>
                                    <th class="green--text">MOBILE</th>
                                    <th class="green--text">COURSE</th>
                                    <th class="green--text">DATE OF PURCHASED</th>
                                    <th class="green--text">LOCALITY</th>
                                    <!-- <th class="green--text">FRANCHISE CODE</th> -->
                                    <th class="green--text">PRICE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in courses" :key="item._id"> 
                                     <td>{{ index + 1 }}</td>
                                     <td>{{item.user.name}}</td>
                                     <td>{{item.user.phone}}</td>
                                    <td>{{item.course}}</td>
                                    <td>{{item.created |  moment("Do MMM  YYYY, h:mm:ss a")}}</td>
                                    <td>{{item.user.locality}}</td>
                                    <td>{{10000}}</td>

                                </tr>
                                 <tr><td class="green--text"><b>TOTAL :</b>  <span class="red--text"><b>{{totalAcademy}}</b></span> </td></tr> 
                                <tr><td class="green--text"><b>AMOUNT PAYABLE : </b><span class="red--text"><b>{{totalAcademy/10}}</b></span></td></tr> 
                            </tbody>
                        </v-simple-table>
                        </div>
                </tr>
                <br><br>
                <tr>
                    <td  class="caption"><b>TA & ASCC COURSE PURCHASED IN <p class="caption"><span v-for="items in FRANCHISE_DETAILS.pos" :key="items.pin">{{items.postoffice}}, </span></p></b>{{FRANCHISE_DETAILS.po}}</td>
                     <div >
                        <v-simple-table class="mx-5">
                            <thead>
                                <tr>
                                    <th class="green--text">SL NO </th>
                                    <th class="green--text">USER NAME</th>
                                    <th class="green--text">MOBILE</th>
                                    <th class="green--text">COURSE</th>
                                    <th class="green--text">DATE OF PURCHASED</th>
                                    <th class="green--text">POST OFFICE</th>
                                    <th class="green--text">PRICE</th>
                                    <!-- <th class="green--text">TOTAL</th> -->

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in TAPOFRANCHISE" :key="item._id"> 
                                    <td>{{ index + 1 }}</td>
                                    <td>{{item.user.name}}</td>
                                    <td>{{item.user.phone}}</td>
                                    <td>{{item.course.course}}</td>
                                    <td>{{item.created |  moment("Do MMM  YYYY, h:mm:ss a")}}</td>
                                    <td>{{item.user.po}}</td>
                                    <td>{{item.course.sp}}</td>
                                    <!-- <td>{{item.franchise.code}}</td>  -->
                                </tr>
                                <tr><td class="green--text"><b>TOTAL :</b>  <span class="red--text"><b>{{totalAmount}}</b></span> </td></tr>
                                <tr><td class="green--text"><b>AMOUNT PAYABLE : </b><span class="red--text"><b>{{totalAmount/10}}</b></span></td></tr>
                            </tbody>
                        </v-simple-table>
                        </div>
                </tr>
              
            <v-btn class="green white--text mt-10" @click="sendEmail">SEND EMAIL</v-btn>
            </v-flex>
            <v-flex md-12 xs4 class="pr-5 pl-5">
                <div class="white pa-5">
                    <p class="text-center"><b>LICENSES  <span class="red--text ml-5">{{getCount}}</span></b> <b class="ml-10">SOLD<span class="blue--text ml-5">{{getSoldCount}}</span></b></p>
                     <v-divider class="mb-5"></v-divider>
                     
                     <v-layout xs12 row wrap>
                <v-flex xs12 md4 v-for="item in LICENSES" :key="item._id">
                    <div class="pa-3">
                        <div class="elevation-1 pa-3 text-center">
                            <p v-if="item.status" class="caption pa-0 mb-0 blue--text text-darken-4" style="letter-spacing: 5px;"><b>{{item.licenseCode}} </b></p>
                            <p v-else class="caption pa-0 mb-0 green--text text-darken-2" style="letter-spacing: 5px;"><b>{{item.licenseCode}}</b></p>
                        </div>
                        
                    </div>
                </v-flex>
            </v-layout>
                </div>
            </v-flex>
            
        </v-layout>
    
</template>

<script>

import {mapGetters} from 'vuex'
import Axios from 'axios'

import {BASE_URL} from '../config'
export default {
    data(){
        return{
            password : "",
            courses:[],
            loading:false
        }
    },
 mounted(){

        let payload = {
            id : this.$route.params.id
        }
        this.$store.dispatch("GET_FRANCHISE_DETAILS",payload)
        this.$store.dispatch("GET_LICENSES",payload)
        this.$store.dispatch("GET_TA_PO_FRANCHISE",this.$route.params.id)
        this.getPurchases()
       
       
    },
    computed : {
        ...mapGetters(["FRANCHISE_DETAILS","LICENSES","TAPOFRANCHISE","PROGRESS"]),
        getCount(){
                return this.LICENSES.length
        },
        getSoldCount(){
            let count = 0
            for(let i=0; i<this.LICENSES.length; i++){
                if(this.LICENSES[i].status){
                    count++
                }
            }return count
        },
        totalAmount(){
            let count = 0
            for(let i=0; i<this.TAPOFRANCHISE.length; i++){
                count = count + Number(this.TAPOFRANCHISE[i].course.sp)
            }
            return count
        },
        totalAcademy(){
            let count = 0
            for(let i=0; i<this.courses.length; i++){
                count = count + 10000
            }
            return count
        }
    },
    methods : {
        async getPurchases() {
        this.loading = true
        let url = BASE_URL + "/franchise/purchases/po?id=" + this.$route.params.id
        let {data} = await Axios.get(url)
        this.courses = data
        this.loading = false
    },
        async viewPassword(){
            let url = BASE_URL + "/view-password?id="+this.$route.params.id
            let {data} = await Axios.get(url)
            this.password = data.password
            
        },
        async sendEmail(){
            // {
            //     let url = BASE_URL + "/view-password?id="+this.$route.params.id
            //     let {data} = await Axios.get(url)
            //     this.password = data.password
            // }
            // let emailStr = this.FRANCHISE_DETAILS.email.split("@")[0]
            // let passwordStr = emailStr+ this.FRANCHISE_DETAILS.phone.substring(5,10)
            let url = BASE_URL + "/sendEmail"
            let payload = {
                email : this.FRANCHISE_DETAILS.email,
                name : this.FRANCHISE_DETAILS.name,
                phone : this.FRANCHISE_DETAILS.phone,
                locality : this.FRANCHISE_DETAILS.pincode,
                password : this.password,
                frCode : this.FRANCHISE_DETAILS.code
            }
            
            let {data} = await Axios.post(url,payload)
            this.$toastr.s("SUCCESS", "Email sent successfully");
        },
        editFranchise(){
            this.$router.push({name : "edit-franchise",params : {id : this.$route.params.id}})
        },
        getLocalities(loc){
          let _locs= "";
          for(var i=0;i<loc.length;i++){
              _locs += loc[i].toUpperCase()
              if(loc.length-1!=i){
                  _locs += ", "
              }
          }
          return _locs

      },
    }
}
</script>