<template>
    <v-layout row wrap class="mb-10">
           <v-flex xs12 class="pa-3">
               <v-layout row wrap class="green mt-5 mb-5 pa-3">
                <v-flex xs8 class="pt-3 pb-3">
                    <span class="caption white--text"><b>INSIGHT</b></span>
                    <span class="caption ml-3 mr-3 white--text"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>ADD FRANCHISE</b></span>
                </v-flex>
                <v-flex xs4 class="text-right">
            </v-flex>
            </v-layout>
               <v-layout row wrap class="white pt-10 pb-10">
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>NAME</b></p>
                <v-text-field label="Full Name" v-model="name" outlined class="rounded-0" single-line="" ></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>PHONE</b></p>
                <v-text-field label="Phone" v-model="phone" outlined single-line></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>EMAIL</b></p>
                <v-text-field label="Email" v-model="email" outlined single-line></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>IN CHARGE</b></p>
                <v-text-field label="In charge" v-model="in_charge" outlined single-line></v-text-field>
            </v-flex>
            <v-flex xs12 class="pl-5 pr-5">
                <p class="caption"><b>ADDRESS</b></p>
                <v-textarea label="Address" v-model="address" outlined single-line></v-textarea>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>STATE</b></p>
                <v-text-field label="state" v-model="state" outlined single-line></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>DISTRICT</b></p>
                <v-select
                    :items="districts"
                    label="District"
                    v-model="district"
                    single-line outlined
                    
                ></v-select>
                <!-- <v-text-field label="District" v-model="district" outlined single-line></v-text-field> -->
            </v-flex>
            <v-flex xs12 class="pa-5">
                <v-divider></v-divider>
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption"><b>PINCODE</b></p>
                <v-text-field v-model="pincode"  label="pincode" single-line outlined></v-text-field>
                <v-progress-linear
      indeterminate
      v-if="loading"
      color="green darken-2"
    ></v-progress-linear>
                <!-- <v-select
                    :items="locality_types"
                    label="Panchayat / Munic. / Corp."
                    v-model="locality_type"
                    single-line outlined
                    @change="filterLocalities"
                ></v-select> -->
            </v-flex>
            <v-flex xs6 class="pl-5 pr-5">
                <p class="caption" ><b>POSTOFFICE</b></p>
                <v-select label="Postoffice" v-model="po" outlined single-line  :items="postOffices" item-text="Name"></v-select>
            </v-flex> 
            
            <v-flex xs12 class="pl-5 pr-5 text-right">
                <v-btn large class="green pl-10 pr-10 white--text rounded-0"  @click="addFranchise">ADD FRANCHISE</v-btn>
            </v-flex>
               </v-layout>
           </v-flex>
        </v-layout>
    
</template>

<script>
import Axios from 'axios'
import {BASE_URL, LOCALITY_URL} from '../config'
export default {
    data(){
        return{
            loading : false,
            name : "",
            phone : "",
            email : "",
            in_charge : "",
            address : "",
            location : "",
            district : "",
            panchayaths : [],
            municipalities : [],
            corporations : [],
            districts : ["Alappuzha","Ernakulam","Idukki","Kannur","Kasaragod","Kollam","Kottayam","Kozhikode","Malappuram","Palakkad","Pathanamthitta","Thiruvananthapuram","Thrissur","Wayanad"],
            state : "Kerala",
            pincode  : "",
            postOffices : [],
            po : "",
            filteredLocalities : [],
        }   
    },
    computed : {
      
    },
    watch : {
        pincode : async function(newVal,oldVal){
           if(newVal.length == 6){
               this.loading = true
               let {data} = await Axios.get("https://api.postalpincode.in/pincode/"+this.pincode)
               this.postOffices = data[0].PostOffice
               this.loading = false
           }
        }
    },
    methods : {
        addFranchise(){
            let payload = {
                name : this.name,
                phone : this.phone,
                email : this.email,
                in_charge : this.in_charge,
                address : this.address,
                pincode : this.pincode,
                po : this.po,
                
                district : this.district,
                state : this.state,
            }
            this.$store.dispatch("ADD_FRANCHISE_PINCODE",payload)
            this.$toastr.s("SAVED", "Account added successfully");
            this.name = ""
            this.phone = ""
            this.email = ""
            this.in_charge = ""
            this.address = ""
            this.location = ""
            this.district = ""
            this.state = ""
            this.pincode = ""
            this.po = ""
            this.localities = []
        },
        // async getLocalities(){
        //     let url = "http://65.0.174.90:5000/api/v1" + "/localities/?district="+this.district
        //     let {data} = await Axios.get(url)
        //     this.localities = data
        // },
        // filterLocalities(){
        //     this.filteredLocalities = []
            
        //     for(let  i=0;i<this.localities.length;i++){
        //         if(this.localities[i].type == this.locality_type.toUpperCase()){this.filteredLocalities.push(this.localities[i])}                   
        //     }
            
        // },
    }
}
</script>

