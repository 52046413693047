<template>
     <v-card
    color="grey lighten-4"
    
    height="200px"
    tile
  >
    <v-toolbar extended v-if="LOGIN">
      <v-toolbar-title @click="home"><img src="../assets/logo.png" style="width:100px" class="mt-10 ml-10"/></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text class="green--text mt-5" @click="home">
        FRANCHISES
      </v-btn>
      <v-btn text class="green--text mt-5" @click="taluk">
        TALUK FRANCHISES
      </v-btn>

      <v-btn text class="green--text mt-5" @click="po">
        POST OFFICE
      </v-btn>
    <v-btn text class="green--text mt-5" @click="states">
        STATES
      </v-btn>
      <v-btn text class="green--text mt-5" @click="logout">
        LOGOUT
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
      computed:{
        ...mapGetters(['ADMIN','LOGIN'])
      },
      methods : {
        home(){
            this.$router.push('/')
        },
        taluk(){
          this.$router.push('/states/taluk')
        },
        po(){
            this.$router.push('/franchises-pincode')
        },
        states(){
          this.$router.push('/states')
        },
        logout(){
          this.$router.push('/login')
          this.$store.dispatch('SET_LOGIN',false)
        }
    }
}
</script>