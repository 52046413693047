import Axios from 'axios'
import {
    BASE_URL
} from '../config'

const state = {
    licenses : [],
    myLicenses : []
    
}   
const getters = {
    LICENSES: state => {
        return state.licenses
    },
    MY_LICENSES: state => {
        return state.myLicenses
    },
    
}
const mutations = {
    SET_LICENSES : (state,payload) => {
        state.licenses = payload
    },
    SET_MY_LICENSES : (state,payload) => {
        state.myLicenses = payload
    },
    
}
const actions = {
    GENERATE_LICENSES : async (context, payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'post',
            data : payload,
            url : BASE_URL+'/license',
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
        //  context.dispatch("GET_FRANCHISES")
         context.dispatch("SET_PROGRESS",false)
         let pld = {
             id : payload.franchiseId
         }
         context.dispatch("GET_LICENSES",pld)
    },
    GET_LICENSES : async(context,payload) => {
        
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'get',
            url : BASE_URL+'/license/'+payload.id,
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
        context.commit("SET_LICENSES",data)
        context.dispatch("SET_PROGRESS",false)
    },
    GET_MY_LICENSES : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'get',
            url : BASE_URL+'/my-licenses',
            headers: {
                'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
         
        context.commit("SET_MY_LICENSES",data)
        context.dispatch("SET_PROGRESS",false)
    },
    DELETE_LICENSE : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'delete',
            url : BASE_URL+'/delete-license/'+ payload,
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
        context.commit("DELETE_LICENSE")
        context.dispatch("SET_PROGRESS",false)
    },
    
}

export default {
    state,
    getters,
    mutations,
    actions
}