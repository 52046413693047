<template>
        <v-layout row wrap class="">
             <v-flex xs12 class="pa-3">
                 <v-layout row wrap class="green mt-5 mb-5 pa-3">
                <v-flex xs8 class="pt-3 pb-3">
                    <span class="caption white--text"><b>INSIGHT</b></span>
                    <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>FRANCHISE-POST OFFICE</b></span>
                </v-flex>
                <v-flex xs4 class="text-right pt-2">
                <v-btn @click="addFranchise" class="white black--text pl-10 pr-10" rounded><b>ADD FRANCHISE</b></v-btn>
                
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 class="mt-2 white pa-5">
            <v-layout row wrap>
                <v-flex xs12 md12 class="pr-1">
                    <v-text-field color="green" label="search" single-line outlined v-model="searchText" ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 md2> -->
                    <!-- <v-expand-transition > 
                         <v-btn v-show="searchText != ''" class="green white--text" block  style="height:55px">search</v-btn>
                         </v-expand-transition> -->
                <!-- </v-flex> -->
            </v-layout>
        </v-flex>
          <v-flex xs12>
                <v-simple-table class="px-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th></th>
          <th class="text-left">Name</th>
          <th class="text-left">File Number</th>
          <th class="text-left">Phone</th>
          <th class="text-left">District</th>
          <th class="text-left">Pincode</th>
          <th class="text-left">Post Office</th>
          <th>Code</th>
          <!-- <th>Password</th> -->
          <th>Actions</th>
          <!-- <th class="text-left">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in filteredSearch" :key="item._id">
          <td>{{index+1}}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.file_no }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.district }}</td>
          <td  class="grey--text">{{ item.pincode }}</td>
          <td  class="grey--text" v-if="item.pos != ''">{{item.po}} ,{{ item.pos[0].postoffice}}</td>
          <td  class="grey--text" v-else>{{ item.po}}</td>
          <td class="green--text">{{item.code}}</td>
          <!-- <td class="pink--text"> {{ getPassword(item) }}</td> -->
          
          

          <td class="d-inline-flex pa-2">
            <v-icon class="green--text" @click="viewFranchiseDetails(item._id)" >search</v-icon>
            <v-icon class="green--text text--darken-4 ml-2" @click="editFranchiseDetails(item._id)" >edit</v-icon>
            <!-- <v-icon class="green--text text--darken-4 ml-5" @click="licenses(item._id)" >list</v-icon> -->
            <v-icon v-if="ADMIN" class="pink--text ml-2" @click="deleteFranchise(item._id)">delete</v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
            </v-flex>
        </v-layout>
    
</template>

<script>
import {mapGetters} from 'vuex'
import Axios from 'axios'
import { BASE_URL } from '../config'
export default {
    data(){
      return{
        searchText:null,
      }
    },
    computed : {
        ...mapGetters(['FRANCHISES','ADMIN']),
         filteredSearch(){
                  if(this.searchText){
                  return this.FRANCHISES.filter((item)=>{
                    if(item.district != null){
                      return (this.searchText.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v)) 
                    || this.searchText.toLowerCase().split(' ').every(v => item.phone.toLowerCase().includes(v))
                    || this.searchText.toLowerCase().split(' ').every(v => item.district.toLowerCase().includes(v))
                    || this.searchText.toLowerCase().split(' ').every(v => item.pincode.toLowerCase().includes(v))
                    || this.searchText.toLowerCase().split(' ').every(v => item.po.toLowerCase().includes(v))
                    // || this.searchText.toLowerCase().split(' ').every(v => item.file_no.toString().toLowerCase().includes(v))

                    )
                    }
                    
                  })
                  }else{
                    return this.FRANCHISES;
                  }
                }
                
    },
    mounted(){
        this.$store.dispatch("GET_FRANCHISES_PINCODE")
    },
    methods : {
      async sendMail(email,name,locality,phone,frCode,id){
        let emailStr = email.split("@")[0]
        let passwordStr = emailStr+ phone.substring(5,10)
        let url = "http://3.7.194.167:5006/api/v1/sendEmail"
        let payload = {
          email : email,
          name : name,
          locality : locality,
          phone : phone,
          password : passwordStr,
          frCode : frCode,
          id : id
        }
        let result = await Axios.post(url,payload)
        
      },

      // async savePassword(){
      //   var url = BASE_URL + "/add-passwords"
      //   console.log(url)
      //   for(let i=0;i<this.FRANCHISES.length;i++){
      //     let item = this.FRANCHISES[i]
      //     let emailStr = item.email.split("@")[0]
      //     let passwordStr = emailStr+ item.phone.substring(5,10)
      //     let payload = {
      //         password : passwordStr,
      //         id : item._id
      //       }
      //     let {data} = await Axios.post(url,payload)
      //     console.log(data)
          
      //   }
      // },

      getPassword(item){
        
        let emailStr = item.email.split("@")[0]
        let passwordStr = emailStr+ item.phone.substring(5,10)
        return passwordStr
      },
      getLocalities(loc){
          let _locs= "";
          for(var i=0;i<loc.length;i++){
              _locs += loc[i].toUpperCase()
              if(loc.length-1!=i){
                  _locs += ", "
              }
          }
          return _locs

      },
      addFranchise(){
        this.$router.push({name : 'add-franchise-pincode'})
      },
      viewFranchiseDetails(id){
        this.$router.push({name : 'franchise-details-pin',params : {id : id}})
      },
      editFranchiseDetails(id){
        this.$router.push({name : "edit-franchise-pincode",params : {id :id}})
      },
      licenses(id){
        this.$router.push({name : "license",params : {id :id}})
      },
     
     deleteFranchise(id){
          swal({
                  title: "Are you sure?",
                  text: "The Franchise will be removed permanantly!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    let payload = {id : id}
                    this.$store.dispatch("DELETE_FRANCHISE",payload)
                    swal("Deleted", "Franchise removed successfully!", {
                      icon: "success",
                    });
                    this.$router.go('')
                  } else {
                    swal("The Franchise was not deleted!");
                  }
                });
              }
    }

}
</script>