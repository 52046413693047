<style>
  form{
    display: block;
    height: 150px;
    width: 100%;
    background: #FFFFFF;
    margin: auto;
    text-align: center;
    line-height: 150px;
    
  }

  div.file-listing{
    /* width: 400px; */
    margin: auto;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  div.file-listing img{
    /* height: 100px; */
  }

  div.remove-container{
    text-align: center;
  }

  div.remove-container a{
    color : #009688;
    cursor: pointer;
  }
  .red-border{
    border:1px solid #009688
  }
  .no-border{
    border: none;
  }
  a.submit-button{
    display: block;
    margin: auto;
    text-align: center;
    width: 200px;
    padding: 10px;
    text-transform: uppercase;
    background-color: #CCC;
    color: white;
    font-weight: bold;
    margin-top: 20px;
  }

  progress{
    width: 400px;
    margin: auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>

<template>
  <div id="file-drag-drop">
    <form ref="fileform" :class="dragActive" style="border:1px solid #E0E0E0">
      <span class="drop-files green--text caption">DROP YOUR FILE HERE</span>
    </form>
    
    <!-- <progress max="100" :value.prop="uploadPercentage" class=""></progress> -->
    <v-progress-linear
      height="10"
      striped
      indeterminate 
      v-if="uploading"
      class="mt-5"
      color="green darken-2"
    ></v-progress-linear>
    <div v-for="(file, key) in files" class="file-listing white pa-8" :key="key">
      <img class="preview" v-bind:ref="'preview'+parseInt( key )" style="width:100%"/>
    </div>
    <br>
    <!-- <p class="white pa-2 caption" v-if="image_url"><b>DISPLAY PICTURE</b></p> -->
    <div class="white pa-8" v-if="image_url">
      <img :src="image_url" width="100%" v-if="!uploading"/>
    </div>
    
    
    <!-- <div class="pt-5">
       <center><v-progress-circular
      indeterminate
      v-if="uploading"
      color="red"
    ></v-progress-circular></center>
    </div> -->

    <!-- <a class="submit-button" v-on:click="submitFiles()" v-show="files.length > 0">Submit</a> -->
  </div>
</template>

<script>
import axios from 'axios'
import {BASE_URL} from '../config'
import {mapGetters} from 'vuex'

  export default {
    /*
      Variables used by the drag and drop component
    */
    data(){
      return {
        image_url : "",
        dragAndDropCapable: false,
        files: [],
        uploadPercentage: 0,
        imageFile : null,
        uploading : false,
        dragOn : false
      }
    },
    props : ['imageUrl'],
    computed :{
    // ...mapGetters(["TOKEN"]),
      dragActive(){
        if(this.dragOn){
          return 'red-border'
        }else{
          return 'no-border'
        }
      }
    },
    mounted(){
      
      this.image_url = this.imageUrl
      /*
        Determine if drag and drop functionality is capable in the browser
      */
      this.dragAndDropCapable = this.determineDragAndDropCapable();

      /*
        If drag and drop capable, then we continue to bind events to our elements.
      */
      if( this.dragAndDropCapable ){
        /*
          Listen to all of the drag events and bind an event listener to each
          for the fileform.
        */
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.fileform.addEventListener(evt, function(e){
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));

        /*
          Add an event listener for drop to the form
        */
        
        this.$refs.fileform.addEventListener('drop', function(e){
          /*
            Capture the files from the drop event and add them to our local files
            array.
          */
        this.dragOn = false
        this.files = []
        this.files.push(e.dataTransfer.files[0])
        this.getImagePreviews();
        this.submitFiles();
        //   for( let i = 0; i < e.dataTransfer.files.length; i++ ){
        //     this.files.push( e.dataTransfer.files[i] );
        //     this.getImagePreviews();
        //     this.submitFiles();
        //   }
        }.bind(this));
        this.$refs.fileform.addEventListener('dragenter', function(e){
          this.dragOn = true
          console.log("ENTER")
        }.bind(this));
        this.$refs.fileform.addEventListener('dragleave', function(e){
          console.log("LEAVE")
          this.dragOn = false
        }.bind(this));
      }
    },

    methods: {
      determineDragAndDropCapable(){
      
        var div = document.createElement('div');
        return ( ( 'draggable' in div )
                || ( 'ondragstart' in div && 'ondrop' in div ) )
                && 'FormData' in window
                && 'FileReader' in window;
        },

            getImagePreviews(){
            for( let i = 0; i < this.files.length; i++ ){
          
            if ( /\.(jpe?g|png|gif)$/i.test( this.files[i].name ) ) {
                        let reader = new FileReader();
            reader.addEventListener("load", function(){
              this.$refs['preview'+parseInt( i )][0].src = reader.result;
            }.bind(this), false);

            /*
              Read the data for the file in through the reader. When it has
              been loaded, we listen to the event propagated and set the image
              src to what was loaded from the reader.
            */
            reader.readAsDataURL( this.files[this.files.length-1] );
          }
          // else{
          //   /*
          //     We do the next tick so the reference is bound and we can access it.
          //   */
          //   this.$nextTick(function(){
          //     this.$refs['preview'+parseInt( i )][0].src = '/images/file.png';
          //   });
          // }
        }
      },

      /*
        Submits the files to the server
      */
      submitFiles(){
        /*
          Initialize the form data
        */
       this.uploading = true
        let formData = new FormData();
        let context = this;

        /*
          Iteate over any file sent over appending the files
          to the form data.
        */
        for( var i = 0; i < this.files.length; i++ ){
          let file = this.files[i];

          formData.append('photos', file);
        }

        /*
          Make the request to the POST /file-drag-drop URL
        */
        axios.post( BASE_URL + '/upload/files',
          formData,
          {
            headers: {
                // 'Authorization': this.TOKEN.replace('%20', " "),
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
              console.log(progressEvent.loaded)
              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
            }.bind(this)
          }
        ).then(function(response){
            context.uploading = false
            context.$emit('uploadSuccess', response.data)
            context.image_url = response.data
            context.files = []
            console.log('SUCCESS!!');
        })
        .catch(function(){
            context.uploading = false
            context.$emit('uploadFailure', "FAILED")
            console.log('FAILURE!!');
        });
      },

      /*
        Removes a select file the user has uploaded
      */
      removeFile( key ){
        this.files.splice( key, 1 );
      }
    }
  }
</script>