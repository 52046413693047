import Axios from 'axios'
import {
    BASE_URL
} from '../config'

const state = {
    progress:false,
    franchises : [],
    franchiseDetails : {},
    panchayaths : [],
    franchiseStockOrders : [],
    progress :"",
    taFranchise:[],
    taPoFranchise:[]
}   
const getters = {
    FRANCHISES: state => {
        return state.franchises 
    },
    FRANCHISE_DETAILS : state => {
        return state.franchiseDetails.result
    },
    LOCALITY_DETAILS : state => {
        return state.franchiseDetails.locality
    },
    PANCHAYATHS : state => {
        return state.panchayaths
    },
    FRANCHISE_STOCK_ORDERS : state => {
        return state.franchiseStockOrders
    },
    PROGRESS : state => {
        return state.progress
    },
    TAFRANCHISE : state => {
        return state.taFranchise
    },
    TAPOFRANCHISE : state => {
        return state.taPoFranchise
    },
    PROGRESS : state => {
        return state.progress
    }
    
}
const mutations = {
    SET_PROGRESS : (state,payload) => {
        state.progress = payload
    },
    SET_FRANCHISES : (state,payload) => {
        state.franchises = payload
    },
    SET_FRANCHISE_DETAILS : (state,payload) =>{
        state.franchiseDetails = payload
    },
    SET_PANCHAYATHS : (state,payload) => {
        state.panchayaths = payload
    },
    SET_FRANCHISE_STOCK_ORDERS : (state,payload) => {
        state.franchiseStockOrders = payload
    },
    SET_PROGRESS : (state,payload) => {
        state.progress = payload
    },
    GET_TA_FRANCHISE : (state,payload) => {
        state.taFranchise = payload
    },
    GET_TA_PO_FRANCHISE : (state,payload) => {
        state.taPoFranchise = payload
    }

}
const actions = {
    ADD_FRANCHISE : async (context, payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'post',
            data : payload,
            url : BASE_URL+'/franchise',
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
         context.dispatch("GET_FRANCHISES")
         context.dispatch("SET_PROGRESS",false)
    },
    ADD_FRANCHISE_PINCODE : async (context, payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'post',
            data : payload,
            url : BASE_URL+'/franchise/po',
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
         context.dispatch("GET_FRANCHISES")
         context.dispatch("SET_PROGRESS",false)
    },
    UPDATE_FRANCHISE : async (context,payload)=>{
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'post',
            data : payload,
            url : BASE_URL+'/franchise/update',
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         }) 
         context.dispatch("SET_PROGRESS",false)
         
    },
    GET_FRANCHISES : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'get',
            url : BASE_URL+'/franchise?state='+payload.state,
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
        context.commit("SET_FRANCHISES",data)
        context.dispatch("SET_PROGRESS",false)
    },
    GET_FRANCHISES_TALUK : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'get',
            url : BASE_URL+'/franchise/taluk?state='+payload.state,
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
        context.commit("SET_FRANCHISES",data)
        context.dispatch("SET_PROGRESS",false)
    },
    GET_FRANCHISES_PINCODE : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'get',
            url : BASE_URL+'/franchise-pincode',
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
        context.commit("SET_FRANCHISES",data)
        context.dispatch("SET_PROGRESS",false)
    },
    GET_FRANCHISE_DETAILS : async(context,payload)=>{
        context.dispatch("SET_PROGRESS",true)
        let {data} = await Axios({
            method : 'get',
            url : BASE_URL + "/franchise/details/"+payload.id,
            headers : {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
        })
        context.commit("SET_FRANCHISE_DETAILS",data)
        context.dispatch("SET_PROGRESS",false)
    },
    SAVE_FRANCHISE_DOCUMENT  :  async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data} = await Axios({
            method : 'post',
            data : payload,
            url : BASE_URL + "/franchise/documents/",
            headers : {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
        })
        context.dispatch("GET_FRANCHISE_DETAILS",payload)
        context.dispatch("SET_PROGRESS",false)
    },
    GET_PANCHAYATHS : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data} = await Axios({
            method : 'get',
            url : BASE_URL + "/panchayath/list/"+payload.district,
            headers : {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
        })
        context.commit("SET_PANCHAYATHS",data)
        context.dispatch("SET_PROGRESS",false)
    },
    DELETE_FRANCHISE : async (context, payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data}  = await Axios({
            method : 'delete',
            data : payload,
            url : BASE_URL+'/franchise/delete?id='+payload.id,
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
         })
         context.dispatch("DELETE_FRANCHISE")
         context.dispatch("SET_PROGRESS",false)
    },
    GET_FRANCHISE_STOCK_ORDERS : async (context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data} = await Axios({
            method : 'get',
            url : BASE_URL + '/franchise/stock/order/list?id'+payload.id,
            headers: {
                // 'Authorization': context.getters.TOKEN.replace('%20', " ")
            }
        })
        context.commit("SET_FRANCHISE_STOCK_ORDERS",data)
        context.dispatch("SET_PROGRESS",false)
    },

    GET_TA_FRANCHISE : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data} = await Axios({
            method : 'get',
            url : BASE_URL + '/franchise/tapurchases/locality?id='+ payload
        })
        context.commit("GET_TA_FRANCHISE",data)
        context.dispatch("SET_PROGRESS",false)
    },

    GET_TA_PO_FRANCHISE : async(context,payload) => {
        context.dispatch("SET_PROGRESS",true)
        let {data} = await Axios({
            method : 'get',
            url : BASE_URL + '/franchise/tapurchases/po?id='+ payload
        })
        context.commit("GET_TA_PO_FRANCHISE",data)
        context.dispatch("SET_PROGRESS",false)
    },

}
export default {
    state,
    getters,
    mutations,
    actions
}