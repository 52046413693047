import Vue from 'vue'
import Vuex from 'vuex'
import franchise from './franchises'
import licenses from './licenses'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    login : false,
    admin : false
  },
  getters : {
    LOGIN: state => {
      return state.login
    },
    ADMIN: state => {
      return state.admin
    },
  },
  mutations: {
    SET_LOGIN: (state, payload) => {
      state.login = payload

  },
  SET_ADMIN: (state, payload) => {
    state.admin = payload
},
  },
  actions: {
    SET_LOGIN : async (context, payload) => {
      context.commit("SET_LOGIN",payload)
    },
    SET_ADMIN: async (context, payload) => {
      context.commit("SET_ADMIN",payload)
    }
  },
  modules: {
    franchise,
    licenses
  }
})
