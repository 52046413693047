import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Franchises from '../views/Franchises'
import FranchisesPincode from '../views/FranchisesPincode'
import FranchiseDetails from '../views/FranchiseDetails'
import FranchiseDetailsPincode from '../views/FranchiseDetailsPincode'
import EditFranchise from '../views/EditFranchise'
import EditFranchisePincode from '../views/EditFranchisePincode'
import AddFranchise from '../views/AddFranchise'
import AddFranchiseOtherStates from '../views/AddFranchiseOtherStates'
import AddFranchiseTaluk from '../views/AddFranchiseTaluk'
import AddFranchisePincode from '../views/AddFranchisePincode'
import States from '../views/States'
import StatesTaluk from '../views/StatesTaluk'
import FranchisesOtherStates from '../views/FranchisesOtherStates'
import FranchisesTaluk from '../views/FranchisesTaluk'
import EditFranchiseOtherStates from '../views/EditFranchiseOtherStates'
import Login from '../views/Login'
import FranchiseSold from '../views/FranchiseSold'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Franchises',
    component: Franchises
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/franchises-states/:state',
    name: 'FranchisesOtherStates',
    component: FranchisesOtherStates
  },
  {
    path: '/franchises-taluk/:state',
    name: 'FranchisesTaluk',
    component: FranchisesTaluk
  },
  {
    path: '/states',
    name: 'States',
    component: States
  },
  {
    path : '/states/taluk',
    name : 'StatesTaluk',
    component : StatesTaluk
  },
  {
    path: '/franchises-pincode',
    name: 'FranchisesPincode',
    component: FranchisesPincode
  },
  {
    path: '/view-franchise-details/:id',
    name: 'franchise-details',
    component: FranchiseDetails
  },
  {
    path: '/view-franchise-details-pin/:id',
    name: 'franchise-details-pin',
    component: FranchiseDetailsPincode
  },
  {
    path : '/edit-franchise/:id',
    name : 'edit-franchise',
    component : EditFranchise
  },
  {
    path : '/edit-franchise-other-states/:id',
    name : 'edit-franchise-other-states',
    component : EditFranchiseOtherStates
  },
  {
    path : '/edit-franchise-pincode/:id',
    name : 'edit-franchise-pincode',
    component : EditFranchisePincode
  },
  {
    path : '/add-franchise',
    name : 'add-franchise',
    component  : AddFranchise
  },
  {
    path : '/add-franchise-other-states/:state',
    name : 'add-franchise-other-states',
    component : AddFranchiseOtherStates
  },
  {
    path : '/add-franchise-taluk/:state',
    name : 'add-franchise-taluk',
    component : AddFranchiseTaluk
  },
  {
    path : '/add-franchise-pincode',
    name : 'add-franchise-pincode',
    component : AddFranchisePincode
  },
  {
    path: '/franchise-sold',
    name: 'FranchiseSold',
    component: FranchiseSold
  },
]

const router = new VueRouter({
  routes
})

export default router
