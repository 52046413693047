<template>
  <v-layout row wrap class="">
       <v-flex xs12 class="pa-3">
           <v-layout row wrap class="green mt-5 mb-5 pa-3">
          <v-flex xs8 class="pt-3 pb-3">
              <span class="caption white--text"><b>INSIGHT</b></span>
              <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
              <span class="caption white--text"><b>FRANCHISE-SOLD</b></span>
              <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right</v-icon></b></span>
              <span class="caption white--text" style="text-transform:uppercase"><b>{{$route.params.state}}</b></span>
          </v-flex>
          <v-flex xs4 class="text-right pt-2">
          <!-- <v-btn @click="addFranchise" class="white black--text pl-10 pr-10" rounded><b>ADD FRANCHISE</b></v-btn> -->
          
          </v-flex>
      </v-layout>
  </v-flex>
    <v-flex xs12>
    </v-flex>
    <v-flex xs12>
          <v-simple-table>
<template v-slot:default>
<thead>
  <tr>
    <th></th>
    <th class="text-left green--text">FRANCHISE NAME{{purchases[0]}}</th>
    <th class="text-left green--text">CODE</th>
    <th class="text-left green--text">DISTRICT</th>
    <th class="text-left green--text">Pcht / Muni / corp</th>
    <th class="text-left green--text">COURSES PURHCASED</th>
    <th>Code</th>
    <!-- <th>Password</th> -->
    <!-- <th>Actions</th> -->
    <!-- <th class="text-left">Action</th> -->
  </tr>
</thead>
<tbody>
  <tr v-for="(item,index) in Franchises" :key="item._id">
    <td>{{index+1}}</td>
    <td>{{ item.name }} <p class="caption">{{item.phone}}</p></td>
    <td >{{ item.code }}</td>
    <td>{{ item.district }}</td>
    <td style="width:200px" class="grey--text">{{ getLocalities(item.locality) }}</td>
    <td v-if="view"><v-btn small @click="viewCourses(item.locality)">View Courses</v-btn></td>
    <td v-else>{{course}}</td>

  </tr>
</tbody>
</template>
</v-simple-table>
      </v-flex>
  </v-layout>

</template>

<script>
import {mapGetters} from 'vuex'
import Axios from 'axios'
import { BASE_URL } from '../config'
import franchises from '../store/franchises'
export default {
  data(){
    return{
      Franchises:[],
      purchases:[],
      view:true,
      course:[]
    }
  },
computed : {
  ...mapGetters(['FRANCHISES','ADMIN']),
 
          
},
async mounted(){
 {
  let url = BASE_URL + "/allfranchise"
  let {data} = await Axios.get(url)
  this.Franchises = data
}
  
},
methods:{
  getLocalities(loc){
          let _locs= "";
          for(var i=0;i<loc.length;i++){
              _locs += loc[i].toUpperCase()
              if(loc.length-1!=i){
                  _locs += ", "
              }
          }
          return _locs

      },
 async viewCourses(locality){
  alert(locality)
    let url = BASE_URL + "/all-purchase-request"
    let {data} = await Axios.get(url)
    if(data.user != ""){
      for(let i=0; i<data.length; i++){
      // if(data[i].user.locality == locality){
      //   this.course.push(data[i].user.course)}
      if(locality != null){
        if(data[i].user.locality == locality)
      {console.log(data[i].user.locality);}
      }
      
    }
    }
    
    this.view = false
  }
}
}
</script>