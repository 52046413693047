<template>
    
        <v-layout row wrap class="mb-10">
            <v-flex xs12>
                 <v-layout row wrap class="green mt-5 mb-5 pa-3">
                <v-flex xs8 class="pt-3 pb-3">
                    <span class="caption white--text"><b>INSIGHT</b></span>
                    <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>FRANCHISE</b></span>
                     <span class="caption ml-3 mr-3"><b><v-icon class="white--text"> keyboard_arrow_right </v-icon></b></span>
                    <span class="caption white--text"><b>EDIT</b></span>
                </v-flex>
                <v-flex xs4 class="text-right pt-2">
                
                <!-- <v-btn @click="editFranchise" class="green white--text rounded-0">EDIT</v-btn> -->
                </v-flex>
            </v-layout>
            </v-flex>
            <v-flex xs12 md8 class="pr-5">
                 <v-simple-table class="pl-5 pr-5">
                <template v-slot:default>
                <tbody>
                    <tr>
                        <td><p class="caption pt-5"><b class="">FILE NUMBER</b></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.file_no" label="File Number" single-line outlined class="mt-8"></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b class="">NAME</b></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.name" label="Name" single-line outlined class="mt-8"></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b>PHONE</b ></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.phone" label="Phone" single-line outlined class="mt-8"></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b>EMAIL</b></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.email" label="Email" single-line outlined class="mt-8"></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b>IN CHARGE</b></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.in_charge" label="In Charge" single-line outlined class="mt-8"></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b>ADDRESS</b></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.address" label="Address" single-line outlined class="mt-8"></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b>STATE</b></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.state" label="State" single-line outlined class="mt-8" disabled></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b>DISTRICT</b></p></td>
                        <td><v-text-field v-model="FRANCHISE_DETAILS.district" label="District" single-line outlined class="mt-8" disabled></v-text-field></td>
                    </tr>
                    <tr>
                        <td><p class="caption pt-5"><b>LOCALITY</b></p></td>
                        <td><v-text-field v-model="locality" label="Panchayath" single-line outlined class="mt-8" disabled></v-text-field></td>
                    </tr>
                    <tr>
                        <td><v-btn @click="save" class="green rounded-0 white--text mt-10 mb-10">Save Details</v-btn></td>
                    </tr>
                </tbody></template></v-simple-table>
                   
            </v-flex>
            <v-flex xs12 md4 class="pl-5 pr-5">
                <div class="white pt-10 pb-10 pl-3 pr-3 mt-5">
                <p class="text-center pb-0 mb-0"><b>LICENSES  <span class="red--text ml-5">{{getCount}}</span></b></p>  
                    <br>
                    <v-text-field label="Count" v-model="licenseCount" single-line outlined></v-text-field>
                    <div class="text-right"><v-btn class="green white--text rounded-0" @click="generateLicenses">GENERATE LICENSES</v-btn></div>
                </div>
                <div class="white pa-3 mt-5">
                    <p class="caption"><b>EDIT LOCALITY</b></p>
                    <v-divider></v-divider>
                    <v-simple-table class="">
                        <tbody>
                            <tr v-for="(item,index) in LOCALITY_DETAILS" :key="item">
                                <td>{{index + 1}}</td>
                                <td>{{item.locality}}</td>
                                <td>{{item.type}}</td>
                                <td>{{ item.district }}</td>
                                <td><v-icon class="red--text" @click="deleteLocality(item.locality)">delete</v-icon></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <!-- <div class="text-right"><v-btn class="green white--text rounded-0">RESET</v-btn></div> -->
                </div>
                <!-- <div class="white pa-3 mt-5">
                    <p class="caption"><b>ADD LOCALITY</b></p>
                    <v-divider></v-divider>
                    
                    
                    <v-flex xs12 class="pl-5 pr-5 mt-5">
                <p class="caption"><b>DISTRICT</b></p>
                <v-select
                    :items="districts"
                    label="District"
                    v-model="district"
                    single-line outlined
                    @change="getLocalities"
                ></v-select>
                
            </v-flex>
            
            <v-flex xs12 class="pl-5 pr-5">
                <p class="caption"><b>LOCALITY</b></p>
                <v-select
                    :items="locality_types"
                    label="Panchayat / Munic. / Corp."
                    v-model="locality_type"
                    single-line outlined
                    @change="filterLocalities"
                ></v-select>
            </v-flex>
            <v-flex xs12 class="pl-5 pr-5">
                <p class="caption" v-if="locality_type == 'Panchayath'"><b>PANCHAYATH</b></p>
                <p class="caption" v-else-if="locality_type == 'Municipality'"><b>MUNICIPALITY</b></p>
                <p class="caption" v-else-if="locality_type == 'Corporation'"><b>CORPORATION</b></p>
                <p class="caption" v-else><b>LOCALITY</b></p>
                <v-select label="Locality" v-model="selectedLocality" outlined single-line :items="filteredLocalities" item-text="locality"></v-select>
            </v-flex> -->
            
                    <!-- <div class="text-right"><v-btn class="green white--text rounded-0" @click="addLocality">ADD</v-btn></div> -->
                <!-- </div> -->
                <div class="white pa-3 mt-5">
                    <p class="caption"><b>RESET PASSWORD</b></p>
                    <v-text-field label="New password" v-model="password" single-line outlined></v-text-field>
                    <div class="text-right"><v-btn class="green white--text rounded-0" @click="updateFranchisePassword">RESET</v-btn></div>
                    <p @click="resetToDefault" class="caption orange--text text--darken-2 pl-2"><u>RESET TO DEFAULT</u></p>
                </div>
                
                
            </v-flex>
        </v-layout>
    
</template>

<script>
import {mapGetters} from 'vuex'
import Uploader from '../components/Uploader'
import Axios from 'axios'
import { BASE_URL } from '../config'
export default {
    data(){
        return{
            file_no:"",
            documentTitle : "",
            fileUrl : "",
            localities : [],
            selectedLocality : "",
            licenseCount : '',
            locality : '',
            locality_type : '',
            locality_types: ["Panchayath","Municipality","Corporation"],
            districts : ["Alappuzha","Ernakulam","Idukki","Kannur","Kasaragod","Kollam","Kottayam","Kozhikode","Malappuram","Palakkad","Pathanamthitta","Thiruvananthapuram","Thrissur","Wayanad"],
            name : "",
            phone : "",
            email : "",
            in_charge : "",
            address : "",
            password : ""

        }
    },
    computed : {
        ...mapGetters(['FRANCHISE_DETAILS','LICENSES','LOCALITY_DETAILS']),
        locality(){
            // alert(this.FRANCHISE_DETAILS.locality[0])
            return this.FRANCHISE_DETAILS.locality
        },
        getCount(){
                return this.LICENSES.length
        }
    },  
    components : {
        Uploader
    },
    async mounted(){
        {
            let payload = {
            id : this.$route.params.id
        }
        this.$store.dispatch("GET_FRANCHISE_DETAILS",payload)
        this.$store.dispatch("GET_LICENSES",payload)
        }
        {
            let url = BASE_URL + "/localities/all"
            let {data} = await Axios.get(url)
            this.localities = data
            
        }
        
    },
    methods: {
        async getLocalities(){
            let url = BASE_URL + "/localities?district="+this.district
            let {data} = await Axios.get(url)
            
            this.localities = data
        },
        filterLocalities(){
            this.filteredLocalities = []
            
            for(let  i=0;i<this.localities.length;i++){
                if(this.localities[i].type == this.locality_type.toUpperCase()){this.filteredLocalities.push(this.localities[i])}                   
            }
            
        },
        save(){
            let payload = {
                id : this.$route.params.id,
                name : this.FRANCHISE_DETAILS.name,
                phone : this.FRANCHISE_DETAILS.phone,
                in_charge : this.FRANCHISE_DETAILS.in_charge,
                address : this.FRANCHISE_DETAILS.address,
                email : this.FRANCHISE_DETAILS.email,
                file_no : this.FRANCHISE_DETAILS.file_no
            }
            this.$store.dispatch("UPDATE_FRANCHISE",payload)
            this.$toastr.s("SAVED", "Profile saved successfully");
        },
        generateLicenses(){
            let payload = {
                count : this.licenseCount,
                franchiseId : this.$route.params.id
            }
            this.$store.dispatch("GENERATE_LICENSES",payload)
            this.$toastr.s("LICENSE GENERATED", "Licenses generated and activated");
        },
        saveDocument(){
            let payload = {
                id : this.$route.params.id,
                document_title : this.documentTitle,
                file_url : this.fileUrl
            }
            this.$store.dispatch("SAVE_FRANCHISE_DOCUMENT",payload)

        },
         uploadSuccess(value){
            this.fileUrl = value
        },
        async addLocality(){
            let url = BASE_URL + "/franchise/locality/add"
            let payload = {
                id : this.$route.params.id,
                locality : this.selectedLocality
            }
            let {data} = await Axios.post(url,payload)
            {
                let payload = {
                id : this.$route.params.id
            }
            this.$store.dispatch("GET_FRANCHISE_DETAILS",payload)
            this.district = ""
            this.state = ""
            this.locality_type = ""
            this.locality = ""
            this.selectedLocality = "a"
            }
        },
        async deleteLocality(locality){
            
            
            let url = BASE_URL + "/franchise/locality/delete"
            let payload = {
                id : this.$route.params.id,
                locality : locality
            }
            let {data} = await Axios.post(url,payload)
            {
                let payload = {
                id : this.$route.params.id
            }
            this.$store.dispatch("GET_FRANCHISE_DETAILS",payload)
            }
        },
        async updateFranchisePassword(){
            
        let url = BASE_URL + "/franchise/updatePassword"
            let payload = {
                id : this.$route.params.id,
                phone : this.FRANCHISE_DETAILS.phone,
                password : this.password
            }
            let {data} = await Axios.post(url,payload)
            this.$toastr.s("SAVED", "Profile changed successfully");
            this.password = ""
        },
        async resetToDefault(){
            let emailStr = this.FRANCHISE_DETAILS.email.split("@")[0]
            let passwordStr = emailStr+ this.FRANCHISE_DETAILS.phone.substring(5,10)
            let url = BASE_URL + "/franchise/updatePassword"
            let payload = {
                id : this.$route.params.id,
                phone : this.FRANCHISE_DETAILS.phone,
                password : passwordStr
            }
            let {data} = await Axios.post(url,payload)
            this.$toastr.s("SAVED", "Password reset successfully");
        },
        openUrl(url){
            
            window.open(url, "_blank") 
        }
    }
}
</script>

<style>

     tr:hover {
        background-color: transparent !important;
     }

</style>